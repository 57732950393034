(function ($, Drupal, window, undefined) {
  'use strict';


  Drupal.theme.prototype.requiredMark = function () {
    return $('<span class="form-required" title="' + Drupal.t('This field is required.') + '"> *</span>');
  };


  // (re-) apply the even/odd- & first/last-classes to a table
  $.fn.paintTable = function (options) {
    $(this).each(function () {
      var $table = $(this),
        defaults = {
          rowClasses: {
            even:  'even',
            odd:   'odd',
            first: 'views-row-first',
            last:  'views-row-last'
          }
        },
        settings = $.extend({}, defaults, options),
        $tbody = $table.find('tbody'),
        $rows = $tbody.find('tr'),
        rowLen = $rows.length;


      // throws errors when used as Object.prototype.values
      function getClassNames(obj) {
        var classes = [];

        for (var key in obj) {
          if (obj.hasOwnProperty(key)) {
            classes.push(obj[key]);
          }
        }

        return classes;
      }


      // apply given classes
      function paint() {
        $rows.each(function (i) {
          var $row = $(this),
            classes = [];

          if (i === 0) {
            classes.push(settings.rowClasses.first);
          }

          else if (i === rowLen - 1) {
            classes.push(settings.rowClasses.last);
          }

          classes.push((i % 2) ? settings.rowClasses.even : settings.rowClasses.odd);

          $row.addClass(classes.join(' '));
        });
      }


      // remove classes
      function clean() {
        $rows.removeClass(getClassNames(settings.rowClasses).join(' '));
      }


      // init
      $tbody.detach();
      clean();
      paint();
      $tbody.appendTo($table);
    });

    return this;
  };


  // move the total-row in the cases-overview from thead to tbody
  Drupal.behaviors.dentalThemeTableCaseOverview = {
    attach: function () {
      $('.view-display-id-count_cases').once('total-row', function () {
        var $table = $(this).find('.views-table'),
          $totalRow = $table.find('thead > tr:nth-child(2)').detach();

        // th » td
        $totalRow.addClass('total')
          .find('th').each(function (i) {
          var $cell = $(this),
            $content = $cell.html();

          if (i === 0) {
            $content = Drupal.t('Total');
          }

          $cell.replaceWith('<td>' + $content + '</td>');
        });

        // attach to body
        $totalRow.prependTo($table.find('tbody'));

        // fix the even/odd class
        $table.paintTable();
      });
    }
  };


  // toggle additional anamnese situation fields
  Drupal.behaviors.dentalThemeAnamneseSituationFieldDependency = {
    attach: function () {
      $('#edit-group_situation_transfer').once('field-dependency', function () {
        var $checkboxGroup = $('#edit-field-situation-at-transfer-und'),
          $inputs = $checkboxGroup.find('input'),
          $transferTable = $('#field-situation-at-transfe-help-values'),
          $rows = $transferTable.find('tbody tr'),
          $first_row = $transferTable.find('tbody tr:first'),
          active = 0,
          activeBoxes = [],
          values = [],
          $row;

        // toggle transfer input by checkbox
        function toggleRow(e) {
          var $checkbox = $(e.currentTarget),
            status = $checkbox.prop('checked');

          $checkbox.data('row').toggle(status);
          active = (status) ? active + 1 : active - 1;

          $transferTable.toggle(active > 0);
          if (active < 0) {
            active = 0;
          }
        }

        // init
        $rows.removeClass('even odd');
        $(this).find('.tabledrag-toggle-weight-wrapper').hide();
          $first_row.find('td').hide();

        // relate row to checkbox
        $inputs.each(function (i) {
          var $input = $(this),
            $row = $($rows.get(i)),
            $valueInput = $row.find('input');

          $input.data('row', $row);
          $row.find('td:first').html($input.next().text());

          // collect all values and clear the fields
          values.push($valueInput.val());
          $valueInput.val('');
          if ($input.is(':checked')) {
            activeBoxes.push(i);
          }
        });

        // check that the values are in the correct fields in init
        // (otherwise the values will ignore empty rows)
        for(var i = 0, len = activeBoxes.length; i < len; i += 1) {
          $row = $($rows.get(activeBoxes[i]));
          $row.find('input').val(values.shift());
        }

        $inputs.on('change', toggleRow)
          .trigger('change');
      });
    }
  };


  // add chosen to handle multi-selects
  Drupal.behaviors.dentalThemeMultiSelect = {
    attach: function () {
      $('select[multiple]').once('multi-select', function () {
        $(this).chosen({
          no_results_text:           Drupal.t('No results match'),
          placeholder_text_multiple: Drupal.t('Select some options'),
          placeholder_text_single:   Drupal.t('Select an option')
        });
      });
    }
  };


  // (pain) intensity coloring for form-fields
  Drupal.behaviors.dentalThemeIntensityColor = {
    attach: function () {
      function setIntensityColor($el) {
        $el.attr('data-intensity', $el.val());
      }

      $('#edit-field-intensity-und, #edit-field-pain-intensity-und').once('intensity-color', function () {
        var $this = $(this);

        // listener
        $this.on('change', function () {
          setIntensityColor($this);
        });

        // init
        $this.addClass('intensity-color');
        setIntensityColor($this);
      });
    }
  };


  // Field-dependency for case fields (not manageable by field dependencies).
  Drupal.behaviors.dentalThemeFieldDependencies = {
    attach: function () {
      var dependencies = [
        {
          // Anamnese und Befund » Allgemeine Anamnese » Präoperative Beschwerden
          dependant:  '#edit-field-intensity-und',
          dependee:   '.group-complaints-since-group',
          notEqualTo: '0'
        },
        {
          // Anamnese und Befund » Allgemeine Anamnese » Derzeitige Versorgung
          dependant:  '#edit-field-current-supply-und input',
          dependee:   '.group-age-of-care',
          notEqualTo: 'no supply'
        },
        {
          // Unfall/Perforation/... » Unfall » Bezifferung Zeitraum zwischen Notfalltherapie ...
          dependant:     '#edit-field-time-emergency-treatment-und',
          dependee:      '#edit-field-number-time-beetween',
          notEqualTo:    ['_none', 'None'],
          focusDependee: true
        },
        {
          // Unfall/Perforation/... » Perforation » Dauer
          dependant:     '#edit-field-duration-und',
          dependee:      '#edit-field-duration-time',
          notEqualTo:    ['_none', 'None'],
          focusDependee: true
        },
        {
          // Anamnese und Befund » Allgemeine Anamnese » Präoperable Beschwerden » Beschwerden seit
          dependant:     '#edit-field-complaints-since-values-und',
          dependee:      '#edit-field-complaints-since',
          notEqualTo:    '_none',
          focusDependee: true
        }
      ];

      function elementDependency(obj) {
        var $el = $(obj.dependant),
          $dependeeField = $(obj.dependee),
          $dependeeWrap = $(obj.dependee),
          eventName = 'change.dependency',
          type = $el.attr('type');


        // differenciate between wrap and field
        if (!$dependeeField.is('input') && !$dependeeField.is('select')) {
          $dependeeField = $dependeeWrap.find('input, select');
        }


        // check toggle status by element
        function setToggleStatus(event) {
          var status = true,
            $this = $(event.currentTarget);


          if ($this.attr('type') !== 'radio' || $this.is(':checked')) {
            // check for multiple values
            if (typeof obj.notEqualTo === 'object') {
              for (var i = 0, len = obj.notEqualTo.length; i < len; i += 1) {
                status = status && obj.notEqualTo[i] !== $this.val();
              }
            }
            // check against a single value
            else {
              status = $this.val() !== obj.notEqualTo;
            }

            // disable or enable dependent element and focus is
            if (obj.hasOwnProperty('focusDependee') && obj.focusDependee) {
              $dependeeField.prop('disabled', !status);

              // focus dependee if event changes was not triggered by script
              if (status && !event.hasOwnProperty('isTrigger')) {
                $dependeeField.focus();
              }
            }
            // default toggle; display: block/none
            else {
              $dependeeWrap.toggle(status);
            }
          }
        }

        // listener
        $el.on(eventName, setToggleStatus);

        // init
        if (type === 'radio') {
          // only trigger relevant (checked) element
          $el.filter(function () {
            return this.checked;
          }).trigger(eventName);
        }
        else {
          $el.trigger(eventName);
        }
      }

      $('#case-node-form').once('caseDependency', function () {
        for (var i = 0, len = dependencies.length; i < len; i += 1) {
          elementDependency(dependencies[i]);
        }
      });
    }
  };


  // Disable node edit form for full display.
  Drupal.behaviors.dentalThemeDisableNodeEditForm = {
    attach: function () {
      $('.pane-node-edit-form form :input').prop('disabled', true);
    }
  };


  // [#150921] dynamically count the number of sessions
  Drupal.behaviors.dentalThemeSessionCounter = {
    attach: function () {
      $('#edit-field-sessions').once('session-counter', function () {
        var $this = $(this),
          $dependee = $('.field-name-field-anaesthesy select'),
          $field;

        // get the number of sessions by therapy
        function getSessions() {
          return $dependee.filter(function () {
            return this.value !== '_none';
          }).length;
        }

        // set the number of sessions and toggle rinse rows
        function setSessions() {
          var counter = getSessions();
          $field.val(counter);
        }

        // remove the old display and add a disabled field
        function initField() {
          var $fieldItem = $this.find('.field-items'),
            $wrap = $('<div class="form-item form-type-textfield"></div>'),
            $label = $('<label></label>').appendTo($wrap),
            label = Drupal.t('Sessions');

          // set the label
          $label.text(label);

          // set the field
          $field = $('<input type="text" class="form-text" size="12" />')
            .prop('disabled', true)
            .appendTo($wrap);

          if ($fieldItem.length) {
            // out with the old, in with the new
            $this.find('.field-label').remove();
            $fieldItem.replaceWith($wrap);
          }
          else {
            $this.append($wrap);
          }
        }

        // init field
        initField();
        setSessions();

        // set listener
        $dependee.on('change.session-counter', setSessions);
      });
    }
  };

  // [#278247] adjust the number of medial inlay rows available
  Drupal.behaviors.dentalThemeInlayRowsCounter = {
    attach: function () {
      $('#edit-field-medical-inlay').once('rows-counter', function () {
        var $dependee = $('.field-name-field-channel-rinses select:not(.field_channel_rinses-delta-order)'),
          $dependeeRows = $('#field-channel-rinses-values tbody tr'),
          $inlayRows = $('#field-medical-inlay-values tbody tr'),
          $emptyRowInlay = $('<tr class="empty"></tr>'),
          inlayEmptyText = Drupal.t('You need at least one channel rinse selected to be able to insert medical-inlay-data.');

        // get count of channel rinse not empty rows
        function getRows() {
          var rows = 0;
          $dependeeRows.each(function(index, element) {
            var $selectedValues = $(element).find('select:not(.field_channel_rinses-delta-order)'),
              values = 0;
            $selectedValues.each(function(index, element) {
              values = values + $(element).filter(function () {
                return this.value !== '_none' && this.value !== '';
              }).length;
            });
            if (values > 0) {
              rows = rows + 1;
            }
          });
          return rows;
        }

        // Toggle medical inlay rows
        function setRows() {
          var counter = getRows();

          if (counter > 0) {
            $inlayRows.each(function (i) {
              $(this).toggle(i < counter);
            });
            $emptyRowInlay.hide();
          }
          else {
            $inlayRows.hide();
            $emptyRowInlay.show();
          }
        }

        // initialize medical inlay table data.
        function initField() {

          $('<td>' + inlayEmptyText + '</td>')
            .attr('colspan', $inlayRows.first().children().length)
            .appendTo($emptyRowInlay);
          $emptyRowInlay.appendTo($inlayRows.parent());
        }

        // init field
        initField();
        setRows();

        // set listener
        $dependee.on('change.rows-counter', setRows);
      });
    }
  };


  // Adjusting the case-number label to be displayed as desired.
  Drupal.behaviors.dentalThemeCaseNumberLabel = {
    attach: function () {
      $('#edit-field-case-number .field').once('case-number-label', function () {
        var $this = $(this),
          $oldLabel = $this.find('.field-label'),
          $newLabel = $('<label></label>').text($oldLabel.text().replace(':', ''));

        $this.addClass('form-item');
        $newLabel.prependTo($this);
        $oldLabel.remove();
      });
    }
  };


  // toggle case assignment
  Drupal.behaviors.dentalThemeAssignmentDependency = {
    attach: function () {
      $('#edit-field-case-assignement-und').once('assignment-dependency', function () {
        var $labels = $('.group-general > .horizontal-tabs > .horizontal-tabs-list li'),
          dependencies = [
            {
              dependant: '#edit-field-case-assignement-und-accident',
              dependee:  '.group-dependency-accident'
            },
            {
              dependant: '#edit-field-case-assignement-und-fracture',
              dependee:  '.group-dependency-fracture'
            },
            {
              dependant: '#edit-field-case-assignement-und-perforation',
              dependee:  '.group-dependency-perforation'
            },
            {
              dependant: '#edit-field-case-assignement-und-apikale',
              dependee:  '.group-dependency-apikale'
            }
          ],
          dataValue = 'assignment',
          c; // current

        function toggleTab(event) {
          var $target = $(event.target),
            data = $target.data(dataValue);

          data.$dependee.toggle($target.prop('checked'));
        }

        // relate checkboxes to tabs (tab labels)
        for (var i = 0, len = dependencies.length; i < len; i += 1) {
          c = dependencies[i];
          c.$dependee = $($labels.get($(c.dependee).index()));

          Drupal.theme('requiredMark').insertAfter(c.$dependee.find('a strong'));

          $(c.dependant)
            .on('change', toggleTab)
            .data(dataValue, c)
            .trigger('change');
        }
      });
    }
  };


  // table overflow
  Drupal.behaviors.dentalThemeTableOverflow = {
    attach: function () {
      $('.overflow-fix, .flipped-table .view-content').once('overflow-fix', function () {
        var $wrap = $(this),
          $table = $wrap.find('table:not(.sticky-header)'),
          overflowTxt = Drupal.t('Scroll to see the whole table.'),
          $scrollInfo = $('<div class="overflow-info">' + overflowTxt + '</div>'),
          timer;

        // set the width to prevent overflow
        function setWidth() {
          var width,
            contentWidth = $('#content').width();

          $table.hide();
          $wrap.width('100%');
          width = $wrap.width();

          // fail-save foe exposed-filter
          if (width > contentWidth) {
            width = contentWidth;
          }

          $wrap.width(width);
          $table.show();

          $scrollInfo.toggle($wrap.width() < $table.width());
        }

        // init
        $scrollInfo.insertAfter($wrap);
        setTimeout(setWidth, 100);

        // set listener
        $(window).on('resize.table-overflow', function () {
          clearTimeout(timer);
          timer = setTimeout(setWidth, 100);
        });
      });
    }
  };


  // [#153027] Getting rid of the default "none" option for drop-down-fields.
  Drupal.behaviors.dentalThemeRemoveDefaultFromDropdown = {
    attach: function () {
      var count = 0;
      $('.node-case-form select').once('remove-default', function () {
        var $none;

        $(this).find('option').each(function () {
          var $opt = $(this),
            value = $opt.val().toLowerCase();

          if (value === 'none' && $none.length) {
            $none.remove();
            $none = undefined;
            count += 1;
          }
          else if (value === '_none') {
            $none = $opt;
          }
        });
      });
    }
  };


  // [#] Hide "_none"-value-option for certain fields
  Drupal.behaviors.dentalThemeHideNoneValue = {
    attach: function () {
      var fields = [
          '#edit-field-stimulus-dependence',
          '#edit-field-pain-durability',
          '#edit-field-pain-localisation',
          '#edit-field-percussion-localisation'
        ],
        $fields = $(fields.join(','));

      $fields.once('hide-none-value', function () {
        $(this).find('.form-type-radio:has([value="_none"])').remove();
      });
    }
  };
})(jQuery, Drupal, window, document);
